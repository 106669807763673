html {
  background-color: rgb(253, 251, 255);
}

body {
  margin: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-background: rgb(253, 251, 255);
  --color-text-brighter: rgb(85, 44, 196);
  --color-text: rgb(47, 0, 158);
  --color-foreground: #0f0e11;
}

h1 {
  font-size: 40pt;
  margin: 0;
  margin-inline: 0;
}

h2 {
  font-weight: normal;
  margin-block: 15px;
  margin-inline: 0;
}

h3 {
  font-weight: normal;
  color: var(--color-text);
  margin-block: 15px;
  margin-inline: 0;
}

h4 {
  color: var(--color-text);
  margin: 0;
}

p {
  font-size: 12pt;
  margin-block: 5px;
  margin-inline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark {
  background-color: var(--color-text);
  color: var(--color-background);
}

.regular {
  background-color: var(--color-text-brighter);
  color: var(--color-background);
}

.color {
  color: var(--color-text);
}

.white {
  background-color: var(--color-background);
  color: var(--color-foreground);
}

.lowershadow {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  position: relative;
}

.simplebutton {
  border: 0;
  text-align: center;
  padding: 10px;
}
.simplebutton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tagbutton {
  background-color: var(--color-text);
  border: 0;
  text-align: center;
  padding: 10px;
  margin-right:"5px";
  padding-inline: "10px";
  padding-block: "5px";
  border-radius: "15px";
}

.portfoliobutton {
  border: 0;
  background-color: var(--color-background);
  display: flex;
  padding-inline: 20px;
  text-align: left;
}
.portfoliobutton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.description {
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem;
}

.sidebarbutton {
  min-width: 25vh;
  border: 0;
  text-align: start;
  padding-inline: 20px;
}
.sidebarbutton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.closesidebarbutton {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0;
  border: 0;
  text-align: center;
  position: fixed;
  top: 60px;
}
.closesidebarbutton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
